body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
	--primary-color: #368FF7;
	--white: ghostwhite;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.badges--queueHOC {
   margin-right: 14px !important;
}
.badges--queueHOC .ant-badge-status-dot {
  width: 20px;
  height: 20px;
}
.queueHOC--tabs .ant-tabs-bar {
	margin: 0px;
	text-align: left;
	background-color: #368FF7;
	background-color: var(--primary-color);
}
.queueHOC--tabs .ant-tabs-ink-bar {
	background-color: ghostwhite;
	background-color: var(--white);
}
.queueHOC--tabs .ant-badge-count {
	color: #368FF7;
	color: var(--primary-color);
	background-color: ghostwhite;
	background-color: var(--white);
}
.queueHOC--tabs .ant-tabs-nav .ant-tabs-tab {
	color: #AFD2FC;
} 
.queueHOC--tabs .ant-tabs-nav .ant-tabs-tab-active,
.queueHOC--tabs .ant-tabs-nav .ant-tabs-tab:hover {
	color: ghostwhite;
}

.active {
  color: white;
}
.m-3 {
  margin-top: 1rem;
  text-align: left;
}
.sideBar--list {
  text-align: left;
  height: "100%"
}
.sideBar--list .ant-col {
  padding: 1rem;
}
.button--queueItem-more {
	border: none !important;
	background-color: ghostwhite !important;
}
.ant-btn.investigation-ordered, 
.ant-btn.investigation-ordered:focus {
	background-color: #FF900D;
	border-color: #FF900D;
	color: white;
	width: 100%;
}
.ant-btn.investigation-received, 
.ant-btn.investigation-received:focus {
	background-color: #31AA31;
	border-color: #31AA31;
	color: white;
	width: 100%;
}
.ant-btn.investigation-notConducted,
.ant-btn.investigation-notConducted:focus {
	background-color: #E53A3A;
	border-color: #E53A3A;
	color: white;
	width: 100%;
}
.ant-btn.investigation-muted, 
.ant-btn.investigation-muted:focus {
	width: 100%;
}
#dynamicVitals .ant-input-number {
	border: 1px solid black !important;
}
.suggestions-container {
	border-radius: 4px;
	border: 1px solid darkgray;
}
.suggestion {
	padding: 0.2rem;
}
.suggestion:hover {
	background-color: gray;
	color: white;
	cursor: pointer;
}
.registration--label {
	color: gray;
}
mark {
	background-color: #52c41a !important;
	color: white !important;
	border-radius: 6px !important;
}
